<script setup lang="ts">
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import {cn} from '@/lib/utils'
import {ref} from "vue";
import FourthLevelMenuItem from "./FourthLevelMenuItem.vue";
import {chunk} from 'lodash-es'


const props = defineProps({
    menuItems: {type: Array, required: true},
});
const menuItemsShort = props.menuItems

interface MenuItem {
    children: MenuItem[]
    hasActiveChild: boolean
    icon: string
    isActive: boolean
    key: string
    link: string
    properties: any
    title: string
    accentColor: string
    text: string
    ad: {
        link: string
        text: string
        imageSource: string
    }
    type: string
}

const currentTrigger = ref('')
const getOnlyPageChildren = (children: MenuItem[]) => {
    return children.filter((entry) => entry.type === 'page')
}
const hasMoreThanTwoLayers = (menuItem) => {
    return menuItem.children.some((child) => child.children.length > 0)
}
const isLinkActive = (child, parent) => {
    const splitted = window.location.pathname.split('/')
    if (parent.link !== '/' + splitted[splitted.length - 1] || parent.key !== splitted[splitted.length - 1]) {
        return child.link.includes(splitted[splitted.length - 1].toString()) ? 'active-permament-subchild font-bold' : ''
    }
}

const getColsNumber = (menuItem) => {
    let colsAmount = 0;
    if (hasMoreThanTwoLayers(menuItem)) {
        colsAmount = menuItem.children.length > 5 ? 5 : menuItem.children.length
    } else {
        colsAmount = Math.ceil(menuItem.children.length / 5)
    }
    return Math.min(5,colsAmount)
}
const getColsClass = (menuItem) => {
    return `grid-cols-${getColsNumber(menuItem)}`
}
const containsAd = (menuItem) => {
    return !!menuItem.ad
}

const secondLevelMenuItemClasses = "cursor-pointer hover:bg-white/20 text-white hover:text-white focus:text-white font-bold"
const thirdLevelMenuItemClasses = "text-gray-700 uppercase tracking-widest font-black px-2 flex items-center py-2 cursor-pointer"
const fourthLevelMenuItemClasses = "group select-none rounded-md flex pt-3 pb-2 items-center leading-none no-underline outline-none transition-colors hover:bg-white hover:text-black focus:bg-white focus:ring-1 focus:ring-gray-300 focus:text-black"

</script>

<template>
    <NavigationMenu v-model="currentTrigger" disable-click-trigger
                    class="flex flex-wrap !static justify-end items-center">
        <NavigationMenuList class="flex flex-wrap">
            <NavigationMenuItem v-for="menuItem in menuItemsShort" :key="menuItem.key">
                <template v-if="menuItem.children.length>0">
                    <NavigationMenuTrigger :link="menuItem.link" :accent-color="menuItem.accentColor"
                                           :isActive="menuItem.isActive" :class="secondLevelMenuItemClasses"
                    >{{ menuItem.title }}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent
                        class="w-full flex bg-gray-100">
                        <div :class="cn([
                            getColsClass(menuItem),
                            'grid gap-4 p-4 w-full max-w-full'
                            ])">
                            <template v-if="hasMoreThanTwoLayers(menuItem)">
                                <div v-for="child in getOnlyPageChildren(menuItem.children)">
                                    <a :class="cn([
                                                child.icon && 'space-x-2',
                                                thirdLevelMenuItemClasses,
                                                (child.isActive || child.hasActiveChild ) && 'active-permament font-bold',
                                            ])"
                                       :href="child.link"
                                    >
                                        <i v-if="child.icon" :class="['text-lg',  child.icon]"></i>
                                        <span class="text-[14px]" :class="['link-'+menuItem.accentColor]">
                                            {{ child.title }}
                                    </span>
                                    </a>
                                    <ul v-if="child.children.length>0" class="mt-2">
                                        <li v-for="subchild in child.children">
                                            <FourthLevelMenuItem :item="subchild"/>
                                        </li>

                                    </ul>
                                </div>
                            </template>
                            <template v-else>
                                <ul v-for="chunkedMenu in chunk(menuItem.children, 5)">
                                    <li v-for="child in chunkedMenu">
                                        <FourthLevelMenuItem :item="child"/>
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <div v-if="containsAd && menuItem.ad" class="mx-auto mt-2 p-4">
                            <a :href="menuItem.ad.link" class="py-2 relative text-center">
                                <div v-if="menuItem.ad.text"
                                     class="absolute top-8 z-10 z w-full text-white font-bold text-center"
                                     v-html="menuItem.ad.text"></div>
                                <div class="bg-black opacity-25 z-[5] w-full h-full absolute rounded-md"></div>
                                <img alt="ads"
                                     class="hover:shadow-lg hover:bg-black/[.02] rounded-md w-full object-contain mx-auto transition-all ease-in-out duration-200"
                                     :src="menuItem.ad.imageSource"/>
                            </a>
                        </div>
                    </NavigationMenuContent>
                </template>
                <template v-else>
                    <NavigationMenuLink :withUnderline="true"
                                        class="!text-base text-white hover:bg-white/20 hover:text-white focus:text-white !font-bold !py-0"
                                        :href="menuItem.link"
                                        :accent-color="menuItem.accentColor"
                                        :class="[navigationMenuTriggerStyle(), menuItem.isActive && 'active-permament']">
                        {{ menuItem.title }}
                    </NavigationMenuLink>
                </template>
            </NavigationMenuItem>
        </NavigationMenuList>
    </NavigationMenu>
</template>
