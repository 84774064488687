import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Text from "../Text";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";
import Switch from "../fields/Switch";
import YesNo from "../summary/YesNo";
import DatePicker from "../fields/DatePicker";

dayjs.extend(customParseFormat);
let today = dayjs().endOf('date');

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date('');
    });
});

const dayTransform = (value) => {
    return dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD")};

export default {
    groups: [
        {
            title: "Ihre Angaben zur Kündigung",
            description:
                "",
            fields: [
                {
                    type: Select,
                    options: [
                        { value: "ordinary", name: "Ordentlich" },
                        { value: "extraordinary", name: "Außerordentlich" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Um welche Art der Kündigung geht es?",
                    name: "artOfCancelation",
                    submitKey: "art_of_cancelation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["ordinary", "extraordinary"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Text,
                    conditions: (values) => values.artOfCancelation === 'extraordinary',
                    name: "cancelationReason",
                    label: "Grund der Kündigung",
                    submitKey: "cancelationReason",
                },
                {
                    type: Select,
                    options: [
                        { value: "asSoonAsPossible", name: "Schnellstmöglich" },
                        { value: "chooseDate", name: "Datum wählen" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Zu welchem Datum möchten Sie kündigen?",
                    name: "cancelationDate",
                    submitKey: "cancelation_date",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["asSoonAsPossible", "chooseDate"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: DatePicker,
                    label: "Gewünschtes Kündigungsdatum",
                    name: "desiredCancelationDate",
                    minDate: (new Date()).setDate((new Date()).getDate()),
                    maxDate: new Date(2050,0,0),
                    submitKey: "desired_cancelation_date",
                    submitTransform: dayTransform,
                    conditions: (values) => values.cancelationDate === 'chooseDate',
                    validation: Yup.date()
                        .format("DD.MM.YYYY", true)
                        .default("")
                        .typeError("Ungültiges Datum")
                        .required("Bitte ausfüllen"),
                },
            ],
        },
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein')
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                        { value: "spouses", name: "Eheleute" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, { options }) =>
                                options.context.email === value
                        ),
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default(""),
                },
            ],
        },
        {
            title: "Angaben zur abzumeldenden Verbrauchsstelle",
            fields: [
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "address",
                    summary: AddressSummary,
                    label: "Verbrauchsstelle",
                    options: {
                        required: true,
                    },
                    validation: Yup.object().test(
                        "addressRequired",
                        ({value}) => {
                            return {
                                city: !value?.city.length,
                                postcode: !value?.postcode.length,
                                number: !value?.number.length,
                                street: !value?.street.length,
                                message: "Bitte ausfüllen"
                            }
                        },
                        (value) => {
                            return value?.city.length > 0 && value?.postcode.length > 0 && value?.number.length > 0 && value?.street.length > 0;
                        }
                    ).nullable()
                },
                {
                    type: MetaText,
                    description:
                        "Unter welcher Adresse können wir Sie zurzeit erreichen, falls Sie die neue Wohnung noch nicht bezogen haben?",
                },
                {
                    type: Address,
                    name: "temporaryAddress",
                    submitKey: "temporary_address",
                    summary: AddressSummary,
                    label: "Unter welcher Adresse können wir Sie zurzeit erreichen, falls Sie die neue Wohnung noch nicht bezogen haben?",
                },
                {
                    type: Text,
                    name: "annotation",
                    label: "Anmerkungen",
                    submitKey: "annotation",
                },
            ],
        },
        {
            title: "Zähler",
            description:
                "Die Zählerinformationen helfen uns, Ihren Anschluss schnell zuzuordnen und Ihnen möglichst ihren Vertrag zu kündigen",
            fields: [
                {
                    type: Select,
                    options: [
                        { value: "gas", name: "Gas" },
                        { value: "electricity", name: "Strom" },
                        { value: "water", name: "Wasser" },
                        { value: "heat", name: "Fernwärme" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Sparte",
                    name: "selection",
                    submitKey: "meter_selection",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["gas", "electricity", "water", "heat"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Zählernummer",
                    name: "meterNumber",
                    submitKey: "meter_number",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading",
                    submitKey: "meter_reading",
                    validation: Yup.string()
                        .default("")
                },
                {
                    type: MetaText,
                    submitKey: "more2",
                },
                {
                    type: MetaText,
                    submitKey: "more3",
                },
                {
                    type: Switch,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings",
                    submitKey: "more",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Select,
                    options: [
                        { value: "gas", name: "Gas" },
                        { value: "electricity", name: "Strom" },
                        { value: "water", name: "Wasser" },
                        { value: "heat", name: "Fernwärme" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    conditions: (values) => values.moremeterreadings,
                    label: "Sparte",
                    name: "more_selection",
                    submitKey: "more.meter_selection",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["gas", "electricity", "water", "heat"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings,
                    label: "Zählernummer",
                    name: "moreMeterNumber",
                    submitKey: "more.meter_number",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "moreMeterReading",
                    submitKey: "more.meter_reading",
                    validation: Yup.string()
                        .default("")

                },
                {
                    type: Switch,
                    conditions: (values) => values.moremeterreadings,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings2",
                    submitKey: "more2",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Select,
                    options: [
                        { value: "gas", name: "Gas" },
                        { value: "electricity", name: "Strom" },
                        { value: "water", name: "Wasser" },
                        { value: "heat", name: "Fernwärme" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    conditions: (values) => values.moremeterreadings2,
                    label: "Sparte",
                    name: "moreSelection2",
                    submitKey: "more2.meter_selection2",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["gas", "electricity", "water", "heat"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Zählernummer",
                    name: "meterNumber2",
                    submitKey: "more2.meter_number2",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading2",
                    submitKey: "more2.meter_reading2",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Switch,
                    conditions: (values) => values.moremeterreadings2,
                    label: "Weitere Zählerstände melden",
                    name: "moremeterreadings3",
                    submitKey: "more3",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Select,
                    options: [
                        { value: "gas", name: "Gas" },
                        { value: "electricity", name: "Strom" },
                        { value: "water", name: "Wasser" },
                        { value: "heat", name: "Fernwärme" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    conditions: (values) => values.moremeterreadings3,
                    label: "Sparte",
                    name: "moreSelection3",
                    submitKey: "more3.meter_selection3",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["gas", "electricity", "water", "heat"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings3,
                    label: "Zählernummer",
                    name: "meterNumber3",
                    submitKey: "more3.meter_number3",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    conditions: (values) => values.moremeterreadings3,
                    label: "Zählerstand (nur die Zahlen vor dem Komma)",
                    name: "meterReading3",
                    submitKey: "more3.meter_reading3",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                }
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
