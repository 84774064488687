import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

export default {
    groups: [
        {
            title: "Abonnieren Sie den Stadtwerke Herne Business-Newsletter",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und nutzen diese zum Versand unseres Newsletters auf Ihren Wunsch hin.",
            fields: [
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    submitKey: "firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    submitKey: "lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Organisation",
                    name: "organisation",
                    submitKey: "organisation",
                    validation: Yup.string()
                        .default(""),
                },
                {
                    type: Input,
                    label: "Funktion",
                    name: "function",
                    submitKey: "function",
                    validation: Yup.string()
                        .default(""),
                },
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        'Mit der Eingabe meiner Daten und der Betätigung des Buttons „Anmeldung absenden“ erkläre ich mich damit einverstanden, ' +
                        'dass die Stadtwerke Herne AG mich per E-Mail über ihre Produkte der Strom- und Erdgasversorgung sowie Energiedienstleistungen ' +
                        'kontaktiert und zu diesem Zweck meine E-Mail–Adresse, Anrede und meinen Vornamen und Nachnamen verarbeitet. ' +
                        'Diese Einwilligung kann ich jederzeit widerrufen, z. B. per E-Mail an ' +
                        '<a href="mailto:kundenservice@stadtwerke-herne.de">kundenservice@stadtwerke-herne.de</a> ' +
                        'oder indem ich den entsprechenden Abmeldelink am Ende der Mail anklicke. Informationen dazu, wie wir Ihre personenbezogenen Daten ' +
                        'verarbeiten, finden Sie in der ' +
                        '<a href="https://www.stadtwerke-herne.de/datenschutz-nutzungsbedingungen" target="_blank">Datenschutzerklärung</a>.',
                },
            ],
        },
    ],
    submitUrl: "/api/business-newsletter",
};
