import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import Text from "../Text";
import MetaText from "../MetaText";
import Switch from "../fields/Switch";
import DatePicker from "../fields/DatePicker.vue";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        console.log("react", value)
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});
const hernePostcodes = [44623, 44628, 44629,44649, 44652, 44653, 44627, 44628, 44649, 44653, 44651, 44652, 44627, 44623, 44625, 44627, 44628, 44629, 44623, 44625, 	44625, 44652, 44627, 44628, 44628, 44629, 	44649, 44651, 44625, 44627, 44628, 44653, 	44649, 44652, 44653, 44651, 44652, 44653]
const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
          title: "Kontaktdaten/Ansprechpartner:",
          description: "Für eine schnelle Abwicklung der Sponsoring Anfrage, das Formular bitte möglichst vollständig ausfüllen.",
          fields: [
              {
                  type: Input,
                  label: 'Unternehmen / Organisation / Verein',
                  name: 'institutionName',
                  submitKey: 'institutionName',
                  validation: Yup.string()
                      .default('')
              },
              {
                  type: Select,
                  options: [
                      {value: "socials", name: "Soziales"},
                      {value: "culture", name: "Kultur"},
                      {value: "education", name: "Bildung"},
                        {value: "sports", name: "Sport"},
                        {value: "other", name: "Sonstiges"},
                  ],
                  placeholder: "Bitte wählen Sie aus",
                  label: "In welcher Bereich sind Sie tätig?",
                  name: "areaOfActivity",
                  submitKey: "areaOfActivity",
                  summary: SelectSummary,
                  validation: Yup.string()
                      .default("")
              },
              {
                  type: Input,
                  label: 'Vertragskontonummer (Falls vorhanden)',
                  name: 'contractNumber',
                  submitKey: 'contractNumber',
                  validation: Yup.string()
                      .default('')
              },
              {
                  type: Input,
                  label: "Vorname",
                  name: "firstName",
                  size: "half",
                  submitKey: "firstName",
                  validation: Yup.string()
                      .default("")

              },
              {
                  type: Input,
                  label: "Nachname",
                  name: "lastName",
                  size: "half",
                  submitKey: "lastName",
                  validation: Yup.string()
                      .default("")
                      .required("Bitte ausfüllen"),
              },
              {
                  type: Input,
                  label: "Telefonnummer(für Rückfragen)",
                  name: "phone",
                  mask: {
                      mask: "+{49} (\\0) 000 0000000000",
                  },
                  submitKey: "phone",
                  validation: Yup.string().default(""),
              },
              {
                  type: Address,
                  name: "address",
                  submitKey: "address",
                  summary: AddressSummary,
                  label: "Adresse",
                  validation: Yup.object().test('zipCodeFromHerne',({value}) => {
                      return {
                          postcode: !hernePostcodes.includes(parseInt(value?.postcode)),
                          city: value?.city !== "Herne",
                          message: "Sie müssen aus Herne kommen"
                      }
                  }, (value) => {
                      return  hernePostcodes.includes(parseInt(value?.postcode))
                  }).nullable()
              },
              {
                  type: Input,
                  subtype: "email",
                  label: "E-Mail",
                  name: "email",
                  submitKey: "email",
                  validation: Yup.string()
                      .default("")
                      .email("Dies ist keine gültige E-Mail Adresse")
                      .required("Bitte ausfüllen"),
              },
              {
                  type: Input,
                  subtype: "email",
                  label: "E-Mail Bestätigung",
                  name: "emailConfirmation",
                  submitKey: "emailConfirmation",
                  hideInSubmit: true,
                  validation: Yup.string()
                      .default("")
                      .email("Dies ist keine gültige E-Mail Adresse")
                      .required("Bitte ausfüllen")
                      .test(
                          "isSameEmail",
                          (d) => `Die E-Mail Adressen stimmen nicht überein`,
                          (value, {options}) =>
                              options.context.email === value
                      ),
              },
              {
                  type: Input,
                  label: 'Name des Programms oder der Veranstaltung für das Sponsoring / die Spende',
                  name: 'programName',
                  submitKey: 'programName',
                  validation: Yup.string()
                      .default('')
                      .required("Bitte ausfüllen")
              },
              {
                  type: Text,
                  label: "Programm- oder Veranstaltungsziele",
                  name: "programGoals",
                  submitKey: "programGoals",
                  validation: Yup.string()
                      .default('')
                      .max(300, 'Maximal 300 Zeichen')
              },
              {
                  type: DatePicker,
                  label: "Veranstaltungsdatum",
                  name: "eventDate",
                  minDate: (new Date()).setDate((new Date()).getDate()),
                  maxDate: new Date(2030,0,0),
                  submitKey: "eventDate",
                  submitTransform: dayTransform,
                  validation: Yup.date()
                      .format("DD.MM.YYYY", true)
                      .default("")
                      .typeError("Ungültiges Datum")
                      .required("Bitte ausfüllen"),
              },
              {
                  type: Select,
                  options: [
                      {value: "materials", name: "Sachspende (Tombolapreise / Werbeartikel)"},
                      {value: "money", name: "Geldspende"},
                  ],
                  placeholder: "Bitte wählen Sie aus",
                  label: "An welcher Art von Sponsoring / Spende sind Sie interessiert?",
                  name: "sponsoringType",
                  submitKey: "sponsoringType",
                  summary: SelectSummary,
                  validation: Yup.string()
                      .default("")
                      .required("Bitte ausfüllen")
                      .oneOf(
                          ["materials", "money"],
                          "Ungültiger Wert"
                      ),
              },
              {
                  type: Text,
                  label: "Mögliche Gegenleistung (Banner, Beachflags, Anzeigenschaltung etc.)",
                  name: "possibleReturn",
                  submitKey: "possibleReturn",
                  validation: Yup.string()
                      .default('')
                      .max(300, 'Maximal 300 Zeichen')
              },
          ]
        },
        {
            title: "Newsletter",
            hideInSummary: true,
            fields: [
                {
                    type: Switch,
                    name: 'advertisingTerm',
                    label: 'Werbeeinwilligung',
                    submitKey: 'isAdvertisingTermsAccepted',
                    description: 'Ja, ich bin damit einverstanden, dass die Stadtwerke Herne AG mich über ihre Produkte der Strom- und Erdgasversorgung sowie Energiedienstleistungen informiert. Dazu darf die Stadtwerke Herne AG mich per Mail kontaktieren:',
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "advertisingEmail",
                    submitKey: "advertisingEmail",
                    conditions: (values) => values.advertisingTerm === true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: MetaText,
                    title: "Einwilligung",
                    description: "Diese Einwilligung kann ich jederzeit widerrufen, z. B. per Brief an die Stadtwerke Herne AG, Grenzweg 18, 44623 Herne oder per E-Mail kundenservice@stadtwerke-herne.de" +
                        " Informationen dazu, wie wir Ihre personenbezogenen Daten verarbeiten, finden Sie in der " + "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                }
            ]
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },

            ],
        },
    ],
    submitUrl: "/api/form",
};
