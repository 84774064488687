import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import DatePicker from "../fields/DatePicker"
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import MetaText from "../MetaText";
import Switch from "../fields/Switch";
import Text from "../Text";

dayjs.extend(customParseFormat);
let today = dayjs().endOf('date');

const dayTransform = (value) => dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

Yup.addMethod(Yup.date, "legalAge", function (errorMessage) {
    return this.test("test-legal-age", errorMessage, (value) => {
        let birthday = Date.parse(value);
        const diff = Date.now() - birthday.getTime();
        const ageDate = new Date(diff);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age < 18;
    })
})

const dayYupTransform = (value) => {
    value = value.trim();
    if(!value) return null;
    if(value === "__.__.____") return null;
    return value;
}

export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein'),
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        {value: "single", name: "Ledig"},
                        {value: "married", name: "Verheiratet"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Familienstand",
                    name: "maritalStatus",
                    submitKey: "customer_data.maritalStatus",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["single", "married"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: 'Geburtsdatum',
                    name: 'birthday',
                    mask: {
                        mask: Date,
                        min: new Date(1900, 0, 1),
                        max: new Date(),
                        lazy: false
                    },
                    submitKey: 'customer_data.birthday',
                    submitTransform: dayTransform,
                    validation: Yup.string().nullable()
                        .transform(dayYupTransform)
                        .default(null)
                        .matches(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, 'Bitte ein gültiges Datum eingeben')
                },
                {
                    type: Address,
                    name: "address",
                    submitKey: "address",
                    summary: AddressSummary,
                    label: "Ermittlung der Stadt durch die PLZ",
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    submitKey: "customer_data.phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, {options}) =>
                                options.context.email === value
                        ),
                },
                {
                    type: Select,
                    options: [
                        {value: "one", name: "1"},
                        {value: "two", name: "2"},
                        {value: "three", name: "3"},
                        {value: "four", name: "4"},
                        {value: "more_than_four", name: "Mehr als 4"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Haushaltsgröße",
                    name: "householdSize",
                    submitKey: "householdSize",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte auswählen")
                        .oneOf(
                            ["one", "two", "three", "four", "more_than_four"]
                        ),

                },
                {
                    type: Select,
                    options: [
                        {value: "rental", name: "Miete"},
                        {value: "ownership", name: "Eigentum"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Wohnverhältnis",
                    name: "livingCondition",
                    submitKey: "livingCondition",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte auswählen")
                        .oneOf(
                            ["rental", "ownership"]
                        ),
                },

                {
                    type: Input,
                    label: "Beruf",
                    name: "job",
                    submitKey: "job",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        {value: "friends", name: "Freunde"},
                        {value: "family", name: "Familie"},
                        {value: "internet", name: "Internet"},
                        {value: "press", name: "Presse"},
                        {value: "media", name: "Medien"},
                        {value: "radio", name: "Radio"},
                    ],
                    multiple: true,
                    placeholder: "Bitte wählen Sie aus",
                    label: "Wie haben Sie vom Kundenbeirat erfahren?",
                    name: "channel",
                    submitKey: "channel",
                    summary: SelectSummary,
                    validation: Yup.array()
                        .required("Bitte auswählen")
                },
                {
                    type: Text,
                    name: "application",
                    submitKey: "application",
                    label: "Beweggründe Kundenbeirat",
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "privacyPolicy",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                }
            ],
        },
    ],
    submitUrl: "/api/form",
};
