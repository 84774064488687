<script setup>
import { NavigationMenuLink, useForwardPropsEmits } from "radix-vue";

const props = defineProps({
  active: { type: Boolean, required: false },
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  withUnderline: { type: Boolean, required: false, default: false },
    accentColor: { type: String, required: false },
});
const emits = defineEmits(["select"]);

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
  <NavigationMenuLink  v-bind="forwarded">
      <div :class="[{'text-link': withUnderline}, 'link-'+ accentColor]">
          <slot />
      </div>
  </NavigationMenuLink>
</template>
