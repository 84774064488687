<script setup lang="ts">
import {cn} from '@/lib/utils'

const props = defineProps({
    item: {type: Object, required: true},
});

const fourthLevelMenuItemClasses = "group select-none rounded-md flex pt-3 pb-2 items-center leading-none no-underline outline-none transition-colors hover:bg-white hover:text-black focus:bg-white focus:ring-1 focus:ring-gray-300 focus:text-black"

</script>

<template>
    <NavigationMenuLink as-child>
        <a
            :href="item.link"
            :class="cn([
                        fourthLevelMenuItemClasses,
                        item.isActive && 'bg-white',
                        ])"
        >
            <div class="px-2 flex transition-all group-hover:translate-x-2" :class="[item.icon ? 'space-x-2' : '']">
                <div :class="[
                    'flex ',
                    !item.text && 'py-1'
                ]" v-if="item.icon">
                    <i :class="['text-black text-base',  item.icon]"></i>
                </div>
                <div class="flex flex-col justify-center space-y-1">
                    <div
                        :class="cn(['text-black font-medium text-[16px] leading-tight', !item.text && 'py-2', item.isActive && 'text-red-600'])">
                        {{ item.title }}
                    </div>
                    <p v-if="item.text"
                       class="line-clamp-2 text-black text-[0.8rem] leading-snug text-muted-foreground">
                        {{ item.text }}
                    </p>
                </div>
            </div>
        </a>
    </NavigationMenuLink>
</template>
