<template>
    <Combobox as="div">
        <div class="flex justify-center my-12">
            <SearchIcon class="relative top-3.5 left-6 h-5 w-5 text-gray-400" aria-hidden="true" />
            <ComboboxInput class="h-12 w-full max-w-lg border pl-8 pr-4 rounded-lg text-gray-800 placeholder-gray-400 sm:text-sm"
                           :class="[isSearching ? 'rounded-lg' : 'rounded-t-lg! active:rounded-t-lg!', 'focus:ring-0 border-gray-300 focus:outline-none focus-visible:ring-offset-[#e94f2d] focus-visible:ring-opacity-75 focus-visible:ring-offset-2']"
                           placeholder="Suchbegriff"
                           ref="searchInput"
                           @change="query = $event.target.value" />
            <XIcon class="relative top-3.5 right-6 cursor-pointer h-5 w-5 text-gray-400" aria-hidden="true" @click="query = ''" />
        </div>
        <ComboboxOptions v-if="hits.length > 0 && !isSearching"  static class="max-w-lg max-h-96 scroll-py-3 bg-white border-t-2 shadow-gray-600/30 rounded-b-md overflow-y-auto mx-auto p-2">
            <ComboboxOption v-for="item in hits" :key="item.id" :value="item" as="template" v-slot="{ active }">
                <a :href="item.type === 'product'? item.document : item.url">
                    <li v-if="item.type === 'product'" :class="[
                        'flex select-none rounded-lg items-center px-2 py-4',
                        active && 'bg-gray-100 cursor-pointer',
                    ]">
                        <LightBulbIcon class="mr-2 h-10 w-10 text-[#e94f2d]"></LightBulbIcon>
                        <div class="flex-1 text-left">
                                <p :class="[
                                'font-medium',
                                active ? 'text-gray-900' : 'text-gray-700',
                            ]">
                                    {{ item.name }}
                                </p>
                                <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']">
                                    {{ item.description }}
                                </p>

                        </div>
                    </li>
                    <li v-else :class="[
                        'flex select-none rounded-lg items-center px-2 py-4',
                        active && 'bg-gray-100 cursor-pointer',
                    ]">
                        <DocumentTextIcon class="mr-2 h-10 w-10 text-[#e94f2d]"></DocumentTextIcon>
                        <div class="flex-1 text-left">
                            <p v-if="item.name" :class="[
                                'font-medium',
                                active ? 'text-gray-900' : 'text-gray-700',
                            ]">
                                {{ item.name }}
                            </p>
                            <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']" >
                                {{truncateContent(item.description)}}
                            </p>

                        </div>
                    </li>
                </a>
            </ComboboxOption>
        </ComboboxOptions>
        <div v-else-if="isSearching && query !== ''"  class="max-w-lg max-h-96 scroll-py-3 bg-white border-t-2 flex justify-center items-center shadow-gray-600/30 rounded-b-md overflow-y-auto mx-auto p-8">
            <svg
                class="h-6 w-6 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>

        <div v-if="query !== '' && hits.length === 0 && !isSearching" class="max-w-lg py-14 px-6 text-center bg-white border-t-2 shadow-gray-600/30 rounded-b-md text-sm sm:px-14 mx-auto">
            <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" />
            <p class="mt-4 font-semibold text-gray-900">Noch kein Suchergebnis</p>
        </div>
    </Combobox>
</template>

<script setup lang="ts">
import {onMounted,  ref} from "vue";
import { watchDebounced } from "@vueuse/core";
import { FireIcon, LightBulbIcon, SearchIcon, DocumentTextIcon } from "@heroicons/vue/solid";

import {
    ExclamationCircleIcon,
    XIcon,
} from "@heroicons/vue/outline";
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
} from "@headlessui/vue";


const props = defineProps({
    token: String,
    host: String,
    open: Boolean
});

const sectionIcon = {
    gas: FireIcon,
    electricity: LightBulbIcon,
};
const isSearching = ref(true);
const query = ref("");
const hits = ref({});
const searchInput = ref(null)
const truncateContent = (text)=> {
    if(text){
        return text.substring(0, 100) + '...'
    }
}
onMounted(() => {
    window.addEventListener("searchPopoverOpened", ()=> {
        hits.value = []
        isSearching.value = false;
        setTimeout(()=> {
            searchInput.value.$el.focus()
        }, 300)
    });
})
watchDebounced(
    query,
    async () => {
        if (!query.value) {
            return [];
        }

        Promise.all([
            search("products", query.value),
            search("documents", query.value),
        ]).then((found) => {
            let products, documents;
            [products, documents] = found;
            products.forEach(product => {
                product.type = 'product'
            })
            documents.forEach(document => {
                document.type = 'document'
            })
            hits.value = products.concat(documents)

            isSearching.value = false;

        });
    },
    {
        debounce: 500,
        onTrigger() {
            isSearching.value = true;
        },
    }
);
const search = async (index, query) => {
    let results = [];
    addSearchEvent(query)
    await fetch(`${props.host}/indexes/${index}/search`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + props.token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            q: query
        }),
    })
        .then((_) => _.json())
        .then((data) => (results = data.hits));
    return results;
};
const addSearchEvent = (query) => {
    if (window.hasOwnProperty("gtag") && typeof gtag === "function" ) {
        gtag("event", "search", {
            keyword: query,
        });
    }
}
</script>
