import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import FileUpload from "../fields/FileUpload"
import Text from "../Text";
import MetaText from "../MetaText";
import Switch from "../fields/Switch";
import YesNo from "../summary/YesNo";
import Iban from "../Iban";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        console.log("react", value)
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Switch,
                    name: 'isBusinessOwner',
                    label: 'Sind Sie Unternehmer*in?',
                    description: '',
                    submitKey: 'isBusinessOwner',
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Input,
                    label: "Firma",
                    name: "firma",
                    submitKey: "customer_data.firma",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        {value: "female", name: "Frau"},
                        {value: "male", name: "Herr"},
                        {value: "other", name: "Divers"},
                        {value: "spouses", name: "Eheleute"},
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other", "spouses"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                    validation: Yup.string().default("")
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "address",
                    summary: AddressSummary,
                    label: "Ermittlung der Stadt durch die PLZ",
                    options: {
                        required: true
                    },
                    validation: Yup.object().test(
                        "addressRequired",
                        ({value}) => {
                            return {
                                city: !value?.city.length,
                                postcode: !value?.postcode.length,
                                number: !value?.number.length,
                                street: !value?.street.length,
                                message: "Bitte ausfüllen"
                            }
                        },
                        (value) => {
                            return value?.city.length > 0 && value?.postcode.length > 0 && value?.number.length > 0 && value?.street.length > 0;
                        }
                    ).nullable()
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, {options}) =>
                                options.context.email === value
                        ),
                }
        ]},
        {
          title: "Kontodaten",
          description: "Das Konto welches zur Auszahlung der Prämie genutzt werden soll",
          fields: [
              {
                  type: Input,
                  label: 'Kontoinhaber',
                  name: 'accountHolder',
                  submitKey: 'bank_data.account_holder',
                  validation: Yup.string()
                      .required('Bitte ausfüllen')
              },
              {
                  type: Iban,
                  label: 'IBAN',
                  name: 'iban',
                  mask: {
                      mask: 'aa00 0000 0000 0000 0000 0000 0000 0000 00'
                  },
                  submitKey: 'bank_data.iban',
                  placeholder: "DEXX XXXX XXXX XXXX XXXX XX",
                  validation: Yup.string()
                      .required('Bitte ausfüllen')
              },
              {
                  type: Input,
                  label: 'BIC',
                  name: 'bic',
                  submitKey: 'bank_data.bic',
                  placeholder: "XXXX XX XX XXX ",
                  validation: Yup.string()
                      .default('')
              },
          ]
        },
        {
            title: "Fahrzeugdaten",
            description:
                "Für die Bearbeitung Ihrer Anfrage benötigen wir Informationen aus Ihrem Fahrzeugschein",
            fields: [
                {
                    type: Input,
                    label: 'Name des Inhabers der Zulassungsbescheinigung (Feld C.1.1)',
                    name: 'registrationOwnerName',
                    submitKey: 'registration.owner_name',
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                },
                {
                    type: Input,
                    label: "Datum der Zulassung (Feld I)",
                    name: "registrationDate",
                    placeholder: "TT.MM.JJJJ",
                    mask: {
                        mask: Date,
                        min: new Date(1900,0,0),
                        max: new Date(2025,0,0),
                        lazy: false,
                    },
                    submitKey: "registration.date",
                    submitTransform: dayTransform,
                    validation: Yup.date()
                        .format("DD.MM.YYYY", true)
                        .default("")
                        .typeError("Ungültiges Datumsformat")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Fahrzeug-Identifizierungsnummer (Feld E)",
                    name: "registrationVin",
                    submitKey: "registration.vin",
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                        .matches(/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/, "Ungültige Fahrzeug-Identifizierungsnummer")
                    ,
                },
                {
                    type: Input,
                    label: "Antriebsart (Elektro) (Es muss sich um ein reines Batterieelektrofahrzeug handeln) (Feld 10)",
                    name: "registrationDriveType",
                    placeholder: "0000",
                    submitKey: "registration.drive_type",
                    mask: {
                        mask: '0000'
                    },
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                        .min(4, 'Antriebsart muss genau 4-stellig sein')
                        .max(4, 'Antriebsart muss genau 4-stellig sein')
                },
                {
                    type: Input,
                    label: "Fahrzeugklasse (Feld J)",
                    name: "registrationCarClass",
                    submitKey: "registration.car_class",
                    validation: Yup.string().required('Bitte ausfüllen'),
                },
                {
                    type: Text,
                    label: "Anmerkungen",
                    name: "registrationNotes",
                    submitKey: "registration.notes",
                    validation: Yup.string().default('')
                }
            ],
        },
        {
            title: "Fahrzeugschein",
            description:
                "Für die Bearbeitung Ihrer Anfrage benötigen wir die Vorderseite Ihres Fahrzeugschein",
            fields: [
                {
                    type: FileUpload,
                    name: "frontside",
                    submitKey: "files.frontside",
                    label: "Vorderseite Fahrzeugschein hochladen",
                    description: "PDF bis zu 350 KB",
                    validation: Yup.mixed()
                        .test({
                            message: 'Bitte wählen Sie eine PDF aus',
                            test: (file, context) => {
                                const isValid = ['pdf'].includes(file?.name.substring(file?.name.lastIndexOf('.') + 1, file?.name.length));
                                if (!isValid) context?.createError();
                                return isValid;
                            }
                        })
                        .test({
                            message: `Die Datei darf nicht größer als 350 KB sein`,
                            test: (file) => {
                                return file?.size < 350000;
                            }
                        })
                },

            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
                {
                    type: Switch,
                    name: 'acceptedTHGQuote',
                    label: 'THG-Prämie 70€ für das Jahr 2024',
                    description: 'Ich stelle die THG-Quote meines privaten Ladepunkts (Meines E-Fahrzeugs) für das Jahr 2024 zur Verfügung und übermittle hierzu die Zulassungsbescheinigung an die Stadtwerke Herne AG. Ich erhalte einmalig für 2024 und ausschließlich von der Stadtwerke Herne eine Prämie von 70 €. Zudem habe ich die AGB gelesen und stimme diesen zu.',
                    submitKey: 'acceptedTHGQuote',
                    summary: YesNo,
                    validation: Yup.boolean()
                        .oneOf([true], 'Bitte akzeptieren Sie die Bedingungen')
                        .required('Bitte akzeptieren Sie die Bedingungen')
                        .default(false)
                },
            ],
        },
    ],
    submitUrl: "/api/form?dir=thg_quote_assets",
};
