import {createApp} from 'vue/dist/vue.esm-bundler.js'
import TariffCalculator from '@/checkout/TariffCalculator.vue'
import TariffResults from '@/checkout/TariffResults.vue'
import FormRenderer from '@/checkout/FormRenderer.vue'
import Search from '@/components/Search.vue'
import 'swiper/swiper-bundle.css';
import VueClickAway from "vue3-click-away";
import {autoAnimatePlugin} from "@formkit/auto-animate/vue";
import {createPinia} from "pinia";
import "../css/style.css";
import MainMenu from "@/components/navigation/MainMenu.vue";
import MobileMenu from "./components/navigation/MobileMenu.vue";

const pinia = createPinia();

document.querySelectorAll('.vue-wrapper').forEach(function (element) {
    const app = createApp({
        components: {
            'tariffcalculator': TariffCalculator,
            'tariffresults': TariffResults,
            'formrenderer': FormRenderer,
            'search': Search,
            'mainmenu': MainMenu,
            'mobilemenu': MobileMenu
        }
    });
    app.use(pinia);
    app.use(VueClickAway);
    app.use(autoAnimatePlugin);
    app.mount(element);
});

