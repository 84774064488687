import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import Iban from "../Iban";
import Switch from "../fields/Switch";
import YesNo from "../summary/YesNo";
import MetaText from "../MetaText";

dayjs.extend(customParseFormat);
const today = dayjs().endOf('date');

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});


const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .transform(value => !value ? null : value)
                        .default(null)
                        .nullable()
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein'),
                },
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                        { value: "spouses", name: "Eheleute" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(["female", "male", "other", "spouses"], "Ungültiger Wert"),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string().default("").required("Bitte ausfüllen"),
                },
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "temporary_address",
                    summary: AddressSummary,
                    label: "Ermittlung der Stadt durch die PLZ",
                    options: {
                        required: true
                    },
                    validation: Yup.object().test(
                        "addressRequired",
                        ({value}) => {
                            return {
                                city: !value?.city.length,
                                postcode: !value?.postcode.length,
                                number: !value?.number.length,
                                street: !value?.street.length,
                                message: "Bitte ausfüllen"
                            }
                        },
                        (value) => {
                            return value?.city.length > 0 && value?.postcode.length > 0 && value?.number.length > 0 && value?.street.length > 0;
                        }
                    ).nullable()
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, { options }) => options.context.email === value
                        ),
                },
                {
                    type: Input,
                    label: 'Geburtstag',
                    name: 'birthday',
                    mask: {
                        mask: Date,
                        min: new Date(1900, 0, 1),
                        max: new Date(today),
                        lazy: false
                    },
                    submitKey: 'customer_data.birthday',
                    submitTransform: dayTransform,
                    validation: Yup.date()
                        .format('DD.MM.YYYY', true)
                        .default('')
                        .typeError('Ungültiges Datumsformat')
                        .test(
                            'legal-age',
                            (d) => `Sie müssen mindestens 18 Jahre alt sein`,
                            (value) => {
                                const diff = Date.now() - value.getTime();
                                const ageDate = new Date(diff);
                                let age = Math.abs(ageDate.getUTCFullYear() - 1970);
                                return age > 18;
                            }
                        )
                        .required('Bitte ausfüllen'),
                },
            ],
        },
        {
            fields: [
                {
                    type: Switch,
                    label: "Rechnungsanschrift (falls abweichend)",
                    name: "billingAddress",
                    submitKey: "billing",
                    summary: YesNo,
                    validation: Yup.boolean().default(false),
                },
                {
                    type: Address,
                    name: "bAddress",
                    submitKey: "billing.address",
                    conditions:(values) => values.billingAddress,
                    label: "Ermittlung der Stadt durch die PLZ",
                },
            ],
        },
        {
            title: 'Zahlungsart',
            description: "Wie möchten Sie bezahlen?",
            fields: [
                {
                    type: Select,
                    options: [
                        {value: 'sepa', name: 'Lastschrift'},
                        {value: 'transfer', name: 'Überweisung'}
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Zahlungsmethode",
                    name: "paymentType",
                    submitKey: "payment_data.type",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default('')
                        .required('Bitte ausfüllen')
                        .oneOf(['sepa', 'transfer'], 'Ungültiger Wert')
                },
                {
                    type: Input,
                    label: 'Kontoinhaber',
                    name: 'accountHolder',
                    submitKey: 'payment_data.accountHolder',
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                },
                {
                    type: Iban,
                    label: 'IBAN',
                    name: 'iban',
                    mask: {
                        mask: 'aa00 0000 0000 0000 0000 0000 0000 0000 00'
                    },
                    submitKey: 'payment_data.iban',
                    placeholder: "DEXX XXXX XXXX XXXX XXXX XX",
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .required('Bitte ausfüllen')
                },
                {
                    type: Input,
                    label: 'BIC',
                    name: 'bic',
                    submitKey: 'payment_data.bic',
                    placeholder: "XXXX XX XX XXX ",
                    conditions: (values) => values.paymentType === 'sepa',
                    validation: Yup.string()
                        .default('')
                },
            ]
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
                {
                    type: Switch,
                    name: 'customerConstract',
                    label: 'Ich akzeptiere den Vertrag und die allgemeinen Geschäftsbedingungen',
                    submitKey: ['privacy_data.isCustomerContractAccept'],
                    description: "Ich habe die " + "<a href=\'#documents\'>Allgemeinen Geschäftsbedingungen</a> " + "zur Kenntnis genommen und akzeptiere diese. Ich habe die " + "<a href=\'/volljaehrigkeit\' target=\'_blank\'>Erklärung zur Volljährigkeit</a> " + "zur Kenntnis genommen und akzeptiere diese.",
                    validation: Yup.boolean()
                        .oneOf([true], 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen')
                        .required('Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen')
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
