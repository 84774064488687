<script setup>
import { computed } from "vue";
import { NavigationMenuTrigger, useForwardProps } from "radix-vue";
import { navigationMenuTriggerStyle } from "./index";
import { cn } from "@/lib/utils";

const props = defineProps({
    disabled: { type: Boolean, required: false },
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
    isActive: { type: Boolean, required: false },
    link: { type: String, required: false },
    accentColor: { type: String, required: false },
});

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <NavigationMenuTrigger
    v-bind="forwardedProps"
    :class="cn(navigationMenuTriggerStyle(), 'group active', props.class, isActive && 'active-permament')"
  >
      <a class="flex text-base text-link items-baseline justify-center" :class="['link-'+accentColor]" :href="link">
          <div>
              <slot />
          </div>
      </a>
  </NavigationMenuTrigger>
</template>
