<script setup>
import { computed } from "vue";
import { AccordionHeader, AccordionTrigger } from "radix-vue";
import { cn } from "@/lib/utils";

const props = defineProps({
  asChild: { type: Boolean, required: false },
  as: { type: null, required: false },
  class: { type: null, required: false },
});

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <AccordionHeader class="flex">
    <AccordionTrigger
      v-bind="delegatedProps"
      :class="
cn(
  'flex flex-1 items-center justify-between py-2 font-medium transition-all [&[data-state=open]>i]:rotate-180',
  props.class
)"
    >
      <slot />
      <slot name="icon">
          <i class="fa-regular fa-chevron-down relative ml-1 text-xs transition duration-200 transition-transform"></i>
      </slot>
    </AccordionTrigger>
  </AccordionHeader>
</template>
