import dayjs from 'dayjs/esm/index.js'
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Yup from "yup";
import Select from "../fields/Select";
import Input from "../Input";
import Address from "../fields/Address";
import AddressSummary from "../summary/Address";
import SelectSummary from "../summary/Select";
import Switch from "../fields/Switch";
import Text from "../Text";
import MetaText from "../MetaText";

dayjs.extend(customParseFormat);

Yup.addMethod(Yup.date, "format", function (formats, parseStrict) {
    return this.transform(function (value, originalValue) {
        value = dayjs(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date("");
    });
});

const dayTransform = (value) => dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");

export default {
    groups: [
        {
            title: "Persönlichen Angaben",
            description:
                "Wir behandeln Ihre persönlichen Daten selbstverständlich vertraulich und geben diese auch nicht an Dritte weiter. Versprochen.",
            fields: [
                {
                    type: Input,
                    label: "Vertragskontonummer",
                    name: "contractnumber",
                    submitKey: "customer_data.contractnumber",
                    placeholder: "Siehe Rechnung",
                    validation: Yup.string()
                        .default("")
                        .matches(/^[0-9]+$/, "Vertragsnummer muss nur aus Ziffern bestehen")
                        .min(10, 'Vertragsnummer muss zehnstellig sein')
                        .max(10, 'Vertragsnummer muss zehnstellig sein'),
                },
                {
                    type: Input,
                    label: "Unternehmensname",
                    name: "companyname",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Select,
                    options: [
                        { value: "housing", name: "Wohnungswirtschaft" },
                        { value: "industry", name: "Industrie" },
                        { value: "business", name: "Gewerbe" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Kundengruppe",
                    name: "clientGroup",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .oneOf(
                            ["housing", "industry", "business"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Select,
                    options: [
                        { value: "female", name: "Frau" },
                        { value: "male", name: "Herr" },
                        { value: "other", name: "Divers" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Anrede",
                    name: "salutation",
                    size: "half",
                    submitKey: "customer_data.salutation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen")
                        .oneOf(
                            ["female", "male", "other"],
                            "Ungültiger Wert"
                        ),
                },
                {
                    type: Input,
                    label: "Titel",
                    name: "title",
                    size: "half",
                    submitKey: "customer_data.title",
                },
                {
                    type: Input,
                    label: "Vorname",
                    name: "firstname",
                    size: "half",
                    submitKey: "customer_data.firstName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    label: "Nachname",
                    name: "lastname",
                    size: "half",
                    submitKey: "customer_data.lastName",
                    validation: Yup.string()
                        .default("")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Address,
                    name: "shippingAddress",
                    submitKey: "temporary_address",
                    summary: AddressSummary,
                    label: "Ermittlung der Stadt durch die PLZ",
                },
                {
                    type: Input,
                    label: "Telefonnummer",
                    name: "phone",
                    mask: {
                        mask: "+{49} (\\0) 000 0000000000",
                    },
                    submitKey: "customer_data.phone",
                    validation: Yup.string().default(""),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail",
                    name: "email",
                    submitKey: "customer_data.email",
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen"),
                },
                {
                    type: Input,
                    subtype: "email",
                    label: "E-Mail Bestätigung",
                    name: "emailConfirmation",
                    submitKey: "emailConfirmation",
                    hideInSubmit: true,
                    validation: Yup.string()
                        .default("")
                        .email("Dies ist keine gültige E-Mail Adresse")
                        .required("Bitte ausfüllen")
                        .test(
                            "isSameEmail",
                            (d) => `Die E-Mail Adressen stimmen nicht überein`,
                            (value, { options }) =>
                                options.context.email === value
                        ),
                },
                {
                    type: Select,
                    options: [
                        { value: "complaint", name: "Beschwerde" },
                        { value: "energysaving", name: "Energiesparen" },
                        { value: "funding", name: "Förderprogramm" },
                        { value: "guestmaintenance", name: "Gasgerätewartung" },
                        { value: "equaltreatmentofficer", name: "Gleichbehandlungsbeauftragter" },
                        { value: "onlinecutsomersupport", name: "Hilfe zum Online-Kundencenter" },
                        { value: "industrycommercial", name: "Industrie/Gewerbe" },
                        { value: "jobeducation", name: "Job & Ausbildung" },
                        { value: "meter", name: "Alles rund um den Zähler" },
                        { value: "press", name: "Presseanfragen" },
                        { value: "invoice", name: "Rechnung" },
                        { value: "regulatory", name: "Regulierungsmanagement" },
                        { value: "miscellaneous", name: "Sonstiges" },
                        { value: "sponsoring", name: "Sponsoringanfrage" },
                        { value: "urbandrainage", name: "Stadtentwässerung Herne" },
                    ],
                    placeholder: "Bitte wählen Sie aus",
                    label: "Betreff",
                    name: "annotation",
                    submitKey: "selectAnnotation",
                    summary: SelectSummary,
                    validation: Yup.string()
                        .default("")
                        .required("Bitte auswählen")
                        .oneOf(
                            ["complaint", "energysaving", "funding", "guestmaintenance", "equaltreatmentofficer", "onlinecutsomersupport", "industrycommercial", "jobeducation", "press", "invoice", "regulatory", "miscellaneous", "sponsoring", "urbandrainage",],
                            "Ungültiger Wert"
                        ),

                },
                {
                    type: Text,
                    name: "comments",
                    submitKey: "customerMessage",
                    label: "Ihre Nachricht",
                },
            ],
        },
        {
            title: "Datenschutz",
            hideInSummary: true,
            fields: [
                {
                    type: MetaText,
                    name: "customerDisclaimer",
                    label: "Datenschutzbestimmungen",
                    description:
                        "Informationen zum Umgang mit Ihren Daten finden Sie in unserer  " +
                        "<a href='/datenschutz-nutzungsbedingungen' target='_blank'>Datenschutzerklärung</a>.",
                },
            ],
        },
    ],
    submitUrl: "/api/form",
};
