<template>
    <form @submit.prevent="submit" v-if="form">
        <StepSection v-for="group in form.groups" :title="group.title" :description="group.description" :card="group.card"
                     :key="group.title">
            <template
                v-for="field in group.fields"
            >
                <component
                    v-show="!field.hidden"
                    :class="field.size === 'half' ? 'col-span-2 md:col-span-1' : 'col-span-2'"
                    :label="getFieldLabel(field)"
                    :description="field.description"
                    :labels="field.labels"
                    :options="field.options"
                    :mask="field.mask"
                    :default="field.default"
                    :placeholder="field.placeholder"
                    v-if="areConditionsFulfilled(field, formValues)"
                    :error="getFieldError(field, formValues, false, dirtyFields)"
                    :valid="checkIsFieldValid(field, formValues, false, dirtyFields)"
                    :is="field.type"
                    :type="field.subtype"
                    :readonly="field.readonly ?? false"
                    :disabled="field.disabled ?? false"
                    :maxDate="field.maxDate"
                    :minDate="field.minDate"
                    v-model="formValues[field.name]"
                    @set-bic="setBic"
                    @change="dirtyFields.add(field.name)"
                    @blur="dirtyFields.add(field.name)"
                    :multiple="!!field.multiple"
                ></component>
            </template>
        </StepSection>
        <div class="flex justify-end">
            <div class="frc-captcha" ref="friendlyCaptchaElement" data-lang="de"></div>
        </div>
        <div class="flex justify-end">
            <Button @click.prevent="submit()"
                    glow
                    :disabled="!formValues['friendlyCaptchaToken']"
                    :loading="isSubmitting"
                    aria-describedby="submit-form">
                {{getButtonLabel()}}
            </Button>
        </div>
    </form>
</template>

<script setup>
import Button from "./Button";
import StepSection from "./StepSection";
import {areConditionsFulfilled, checkIsFieldValid, getFieldError, getSubmitKeys,} from './domain/steps';
import formSchemas from "./forms";
import {onMounted, reactive, ref} from "vue";
import {has, set} from "lodash";
import {useFormStore} from "./stores/form";
import {WidgetInstance} from "friendly-challenge";


const props = defineProps(['name', 'objectId', 'defaultValues'])

const defaultValues = props.defaultValues ? JSON.parse(props.defaultValues) : {};

const formStore = useFormStore(props.name);
let formValues = formStore().$state;
const form = formSchemas[props.name]

for(let group of form.groups) {
    for (let field of group.fields) {
        if(field.type.name === 'Select' && field.multiple) {
          formValues[field.name] = []
        }
        else if (["Input", "Address", "Text", "Select"].includes(field.type.name)) {
          formValues[field.name] = ""
        }
        else if (["MetaText", "Switch"].includes(field.type.name)) {
          formValues[field.name] = false
        }
        else if (["FileUpload"].includes(field.type.name)) {
          formValues[field.name] = null
        }
    }
}

if (!form) {
  console.error(`Form with name ${props.name} not found`)
}

function friendlyCaptchaCallback(token) {
  formValues['friendlyCaptchaToken'] = token;
}

function getAllGroupFields() {
  return form.groups.map(group => group.fields).flat()
}
function getButtonLabel() {
   if (props.name === 'car_power' || props.name === 'register_water' || props.name === 'annual_payment' || props.name === 'contract_extension') {
       return 'Verbindlich bestellen'
   }
   if(props.name === 'cancel_contract'){
       return 'Jetzt kündigen'
   }

   return 'Abschicken'
}

const dirtyFields = reactive(new Set());

const setBic = (data) => {
  if (data.valid) {
    formValues['bic'] = data.bankData.bic
  }
}

const getFieldLabel = (field) => {
  if (!field.label) {
    return '';
  }
  return field.label + (field.validation?.exclusiveTests?.required ? '*' : '')
}

const prepareSubmitValues = (formValues) => {
  const fields = getAllGroupFields();
  const submitValues = {};
  fields.forEach(field => {
    if (areConditionsFulfilled(field, formValues)) {
      if (field.hideInSubmit === true) {
        return true;
      }
      getSubmitKeys(field, formValues).map(key => {
        const value = formValues[field.name];
        const submitTransform = field.submitTransform || ((v) => v)
        set(submitValues, key, submitTransform(value));
      });
    }
  });

  submitValues.object_id = props.objectId;

  return submitValues;
}

function isFormValid(values) {
  return form.groups.map(g => g.fields).flat().every(field => {
    return !areConditionsFulfilled(field, values) || checkIsFieldValid(field, values, true)
  })
}

function markAllFieldsAsDirty() {
  getAllGroupFields().forEach((field) => {
    dirtyFields.add(field.name)
  })
}

const isSubmitting = ref(false);

const submit = async () => {
  if (!isFormValid(formValues)) {
    markAllFieldsAsDirty()
    return;
  }

  isSubmitting.value = true;
  const submitValues = prepareSubmitValues(formValues);
  set(submitValues, 'friendly-captcha-token', formValues['friendlyCaptchaToken']);

  if (has(submitValues, "files")) {

    const fileData = submitValues['files']
    delete submitValues['files']

    const formData = new FormData();
    formData.append("json", JSON.stringify(submitValues))
    for (let key in fileData) {
      formData.append(key, fileData[key])
    }

    try{
      const response = await fetch(form.submitUrl, {
        method: "POST",
        body: formData
      }).then(response => response.json())
      submitCallbackHandler(response)
    }finally {
      isSubmitting.value = false;
    }

  } else {
    try {
      const response = await fetch(form.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify(submitValues)
      }).then(response => response.json())
      submitCallbackHandler(response);
    } finally {
      isSubmitting.value = false;
    }
  }
}

const submitCallbackHandler = (response) => {
  if (!response.success) {
    return
  }

  if (response.redirectUrl) {
    window.location.href = response.redirectUrl;
  }

  //TODO add possibility to show dialog
}

const friendlyCaptchaElement = ref(null);

onMounted(() => {

  const friendlyCaptchaWidget = new WidgetInstance(friendlyCaptchaElement.value, {
    doneCallback: friendlyCaptchaCallback,
    sitekey: "FCMLQUHKIL2BPAIF",
  });

  friendlyCaptchaWidget.start();

  if (defaultValues) {
    Object.keys(defaultValues).forEach(key => {
      formValues[key] = defaultValues[key]
    })
  }
})

</script>
